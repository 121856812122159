<template>
  <div>
    <v-sheet>
      <v-container>
            <GalleryCom />
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: 'GalleryPage',
  components: {
    GalleryCom: () => import('../components/Gallery.vue'),
  },
  // computed: {
  //   anchor () {
  //     switch (this.$vuetify.breakpoint.name) {
  //       case 'xs': return 'xs-anchor'
  //       case 'sm': return 'sm-anchor'
  //       case 'md': return 'mdAndUp-anchor'
  //       case 'lg': return 'mdAndUp-anchor'
  //       case 'xl': return 'mdAndUp-anchor'
  //       default: return 'mdAndUp-anchor'
  //     }
  //   },
  //   galleryAnchor () {
  //     switch (this.$vuetify.breakpoint.name) {
  //       case 'xs': return 'xs-gallery-anchor'
  //       case 'sm': return 'sm-gallery-anchor'
  //       case 'md': return 'mdAndUp-gallery-anchor'
  //       case 'lg': return 'mdAndUp-gallery-anchor'
  //       case 'xl': return 'mdAndUp-gallery-anchor'
  //       default: return 'mdAndUp-gallery-anchor'
  //     }
  //   },
  // }
}
</script>
<style scoped>
/* .button {
    align-self: center;
    font-style: italic;
    border-radius: 0;
}
.mdAndUp-gallery-anchor {
  position: absolute;
  top: -175px;
}
.sm-gallery-anchor {
  position: absolute;
  top: 370px;
}
.xs-gallery-anchor {
  position: absolute;
  top: 450px;
}

.mdAndUp-anchor {
  position: absolute;
  top: -175;
}
.sm-anchor {
  position: absolute;
  top: -125px;
}
.xs-anchor {
  position: absolute;
  top: -50px;
} */
</style>